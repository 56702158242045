
.wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    background: #66435A;
    height: 100vh;
    width: 100vw;
    overflow: auto;
 
    &__inner {
        @media screen and (max-height: 655px) {
            padding: 70px 0;
        }
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;  

        &__title{
            font-family: 'GothamMedium';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #ffffff !important;
    
        }
    } 
}
  
  