.title{
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* padding-top: 20px; */
    padding-left: 28px;
    color: #333333 !important;

}

.tabStyles{
    background-color: #ffffff;
    border-radius: 30px;
    padding: 4px;
    height: 58px;
}

.nav-tabs {
    border-bottom: 1px solid #e0e0e0 !important;
}

.nav {
    padding-left: 4px !important;
}

.tabStyles .nav-item .nav-link{
    height: 50px !important;
    width: 100% !important;
    border-radius: 30px;
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding-top: 20px;
    color: #333333 !important;
    text-align: center;
    text-transform: capitalize;
    border-bottom: 0px;
}       


.tabStyles .nav-item .nav-link.active{
    height: 50px !important;
    background-color: #A0C23B;
    color: #ffffff !important;
    transition: 0.15s ease-in;
}       

