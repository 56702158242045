body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "GoldmanBold";
  src: local("GoldmanBold"),
    url("./fonts/GothamBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url("./fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url("./fonts/GothamLight.ttf") format("truetype");
}

@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
    url("./fonts/GothamMedium.ttf") format("truetype");
}

@font-face {
  font-family: "GothamMedium_1";
  src: local("GothamMedium_1"),
    url("./fonts/GothamMedium_1.ttf") format("truetype");
}

.ripple-surface {
  padding-top: 16px !important;
}


.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #222;
  background-color: transparent;
  border-radius: 2px;
}

.checkbox-container input:checked~.checkmark {
  background-color: #063040;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #A0C23B;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}