//   primaryPurple: "#66435A",
//   primaryYellow: "#F1C400",
//   primaryGrey: "#75787B",
//   secondaryBlue: "#0E6B8C",
//   secondaryGreen: "#A0C23B",
//   white: "#FFFFFF",
//   customDarkGray: "#333333",
//   borderBlack: "rgba(0, 0, 0, 0.2)"

@mixin toRem($property, $value) {
  #{$property}: ($value / 16) + rem;
}

.wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  background: #66435A;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  &__inner {

    @media screen and (max-height: 655px) {
      padding: 70px 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--logo {
      width: 207.63px;
      margin-bottom: 40px;
    }

    &--card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      border-radius: 30px;
      padding: 30px 50px;


      & .title {
        font-family: 'GothamMedium';
        font-style: normal;
        font-weight: 700;
        @include toRem(font-size, 18);
        color: #333333;
        margin: 4px 0;


      }

      & .login-image {
        width: 160.63px;
        margin: 10px 0;
      }

      & .heading {
        font-family: 'GothamMedium';
        @include toRem(font-size, 16);
        font-style: normal;
        font-weight: 700;
        color: #333333;


      }

      & .form__container {
        width: 300px;

        &--input-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 5px 0;

          & p.label {
            font-family: 'GothamMedium';
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 16px;
            color: #333333;
            margin-bottom: 2px;
          }

          & input.text-input {
            display: flex;
            flex: 1;
            font-size: 14px;
            color: black;
            height: 41px;
            background: #E9E9E9;
            border-radius: 5px;
            padding: 10px 20px;
            border: 0;

            &:focus {
              border: none;
              outline: none;
            }
          }
        }

        &--action-wrapper {
          & .primary-btn {
            width: 100%;
            height: 40px;
            background: #0E6B8C;
            border-radius: 5px;
            color: #FFFFFF;
            border: 0;
            cursor: pointer;
            margin: 20px 0;
    line-height: 41px;


            & button {
              font-family: "GothamMedium";
              background: transparent;
              width: 100%;
              height: 100%;
              border: none;
              color: white;
              cursor: pointer;
            }
          }

          & .secondary-btn {
            background: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            & button {
              font-family: "GothamMedium";
              border: none;
              background: transparent;
              color: #4f4f4f;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {


    &__inner {


      &--logo {
        width: 130px;
        margin-bottom: 20px;
      }

      &--card {

        padding: 20px 30px;


        & .title {
          @include toRem(font-size, 14);
        }

        & .login-image {
          width: 130.63px;
          margin: 10px 0;
        }

        & .heading {
          @include toRem(font-size, 12);
        }

        & .form__container {
          width: 300px;




        }
      }
    }
  }
}

.login {
  background-color: #66435A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // width: 100vw;
}

//.logoStyles {
//     width: 207.63px;
//     height: 60.83px;
//     margin-top: 113px;
//     margin-bottom: 56px;
//}

.container {
  width: 511px;
  height: 643px;
  padding: 10px 60px;
  background: #FFFFFF;
  margin-top: 10px;
  margin: 0px 30px;
  border-radius: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: 600px) {
    width: 200px;
    height: 600px
  }

  .title {
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #333333;
    margin-top: 53px;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    .loginImage {
      width: 198.63px;
      margin-top: 37px;
      height: 115.85px;
    }
  }

  .title2 {
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    margin-top: 38px;
    color: #333333;
  }

  .textFieldContainer {
    @media only screen and (max-width: 600px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    margin-left: 105px;
    margin-right: 105px;
    margin-top: 31px;

    .textFieldStyle {
      font-family: 'GothamLight';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      color: #333333;
      padding-bottom: 4px;
    }

    .textFieldInput {
      // font-family: "GothamMedium";
      font-size: 14px;
      color: black;
      width: 100%;
      height: 41px;
      background: #E9E9E9;
      border-radius: 5px;
      padding-left: 0px;
      border: 0;
      padding-top: 4px;
    }

    .btnStyles {
      width: 100%;
      height: 40px;
      background: #0E6B8C;
      border-radius: 5px;
      color: #FFFFFF;
      border: 0;
      margin-top: 35px;
      margin-bottom: 19px;
      cursor: pointer;
      display: flex;

      button {
        font-family: "GothamMedium";
        background: transparent;
        width: 100%;
        height: 100%;
        border: none;
        color: white;
        cursor: pointer;
        padding-top: 5px;
      }
    }

    .forPassCont {
      display: flex;
      justify-content: center;

      .forgotPass {
        cursor: pointer;
        font-family: 'GothamMedium';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #75787B;
      }
    }
  }

}
