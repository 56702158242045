.mainContainer{
     background-color: #66435A;
     display: flex;
     // flex-direction: row;
     // align-items: center;
     // justify-content: center;
     height: 100vh;
     width: 100%;
     overflow: scroll;


     @media only screen and (max-width: 1150px) {
          padding-left: 20px;
          padding-right: 20px;
     }

     
     .container {
          // background-color: white;
          display: flex;
          // height: 100vh;
          flex-direction: column;
          width: 76vw;
          margin: 20px 56px 16px -20px;
          background-color: #75787a;
          border-radius: 30px;

          @media only screen and (max-width: 1123px) {
               width: 100vw;
               margin-left: 20px;
               margin-right: 20px;
          }

          @media only screen and (max-width: 519px) {
               width: auto;
               margin-left: 0px;
               margin-right: 0px;
          }

     .upperCon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 28px 23px 19px 35px;

          @media only screen and (max-width: 650px) {
               flex-wrap: wrap;
          }

          .newCertificateBtn {
               cursor: pointer;
               width: 150px;
               height: 38px;
               background: #0E6B8C;
               box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
               border-radius: 5px;
               color: #FFFFFF;
               border: none;
               margin-right: 15px;
               // 
               padding-top: 6px;
               // padding-bottom: 10px;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 14px;
               text-align: center;
               letter-spacing: 0.05em;
               color: #FFFFFF;

               @media only screen and (max-width: 650px) {
                    margin-bottom: 10px;
               }
          }

          .changeTempBtn {
               cursor: pointer;
               padding-top: 8px;
               border: none;
               width: 150px;
               height: 35px;
               background-color: #F1C400;
               box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
               border-radius: 5px;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               text-align: center;
               color: #FFFFFF;

               @media only screen and (max-width: 650px) {
                    margin-bottom: 10px;
               }
          }

          .searchBar {
               padding-top: 6px;
               width: 341px;
               height: 34px;
               background-color: #36383c;
               // mix-blend-mode: multiply;
               // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
               border-radius: 30px;
               padding-left: 15px;

               @media only screen and (max-width: 650px) {
                    width: 100%;
               }

               input {
                    background-color: #36383c;
                    width: 301px;
                    height: 85%;
                    border-radius: 30px;
                    padding-left: 4px;
                    border: none;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    color: #FFFFFF;

                    @media only screen and (max-width: 650px) {
                         width: 90%;
                    }
               }

               .iconStyl {
                    width: 12.95px;
                    height: 12.96px;
                    cursor: pointer;
               }
          }
     }


     .table {
          background-color: #0E6B8C;
          border-radius: 5px;
          // width: 80vw;
          margin: 0px 23px 35px 35px;
          max-height: 800px;
          overflow-y: scroll;


          .certificateBtn {
               padding-top: 10px;
               font-family: 'GothamMedium';
               width: 105px;
               height: 35px;
               background: #A0C23B;
               border-radius: 5px;
               border: none;
               color: #FFFFFF;
               cursor: pointer;
          }

          thead tr th {
               background-color: #ffffff;
          }

          tbody tr {
               background-color: #0E6B8C;
          }


          tr th {
               padding-top: 20px;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 700;
               font-size: 14px;
               letter-spacing: 0.05em;
               color: #333333 !important;
               text-align: center;

               div {
                    display: flex !important;
                    align-items: center !important;
                    align-content: center !important;
                    justify-content: center !important;
                    height: 100%;
                    width: 100%;

                    p {
                         align-self: center;
                         margin: 0px !important;
                         padding-top: 5px;
                    }
               }
          }

          td {
               text-align: center;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               color: #FFFFFF !important;
          }

          .deleteIcons {
               width: 19px;
               height: 19px;
               margin: 3px;
               cursor: pointer;
          }

          // .bottom-border>tr,
          // td {
          //      border-bottom: transparent !important
          // }

          .top-border>tr,
          td,
          th {
               border-top: transparent !important;
               border-bottom: transparent !important
          }

          .custom-row-hover:hover tr,
          td {
               background-color: transparent !important;
          }

          .odd {
               background-color: #6f7276;
               border-radius: 5px;
          }

          .even {
               background-color: #75787a;
          }
     }


     .table {
          -ms-overflow-style: none;
          /* Internet Explorer 10+ */
          scrollbar-width: none;
          /* Firefox */
     }

     .table::-webkit-scrollbar {
          display: none;
          /* Safari and Chrome */
     }

     input:focus {
          outline: none;
     }
}}