.mainContainer{
     background-color: #66435A;
     display: flex;
     height: 100vh;
     width: 100%;
     overflow: scroll;


     @media only screen and (max-width: 1150px) {
          padding-left: 20px;
          padding-right: 20px;
     }



     .container {
          display: flex;
          flex-direction: column;
          width: 100vw;
          margin: 20px 56px 16px -20px;
          background-color: #A0C23B;
          border-radius: 30px;

          @media only screen and (max-width: 488px) {
               width: auto;
          }

          @media only screen and (max-width: 1150px) {
               margin-right: 0px;
          }

     .mainCon {
          overflow-y: scroll;
          overflow-x: hidden;
          margin: 42px 88px 0px 35px;
          height: 100%;
          padding-right: 8px;

          @media only screen and (max-width: 1150px) {
               margin-right: 0px;
          }
         
          .contentCont {
               margin-top: 40px;
               position: relative;
               display: flex;
               justify-content: center;
               flex-wrap: wrap;
               flex-direction: column;

               .videoCont {
                    color: white;

                    .videothumb {
                         max-width: 381px;
                         height: 244px;
                         border-radius: 15px;
                         position: absolute;
                    }

                    .videoInnerCon {
                         height: 57px;
                         background: #75787B;
                         border-radius: 15px;
                         position: relative;

                         .indicator {
                              font-family: 'GothamMedium';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 30px;
                              color: #FFFFFF;
                              margin: 16px 0px 0px 21px;
                              position: absolute;
                         }

                         .videoBtn {
                                  width: 206px;
                              height: 40px;
                              border: 0px;
                              background-color: #333333;
                              border-radius: 5px;
                              font-family: 'GothamMedium';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 12px;
                              color: #FFFFFF;
                              padding-top: 8px;
                         }

                         .videoUrl{
                              left: 0;
                              right: 0;
                              top: 0;
                              bottom: 0;
                              margin: auto;
                              position: absolute;
                              display: flex;
                              flex-direction: column;
                              justify-content: center;
                         }
                         .videoUrlInput{
                              width: 80%;
                              background: transparent;
                              border: 0;
                              border-bottom: 1px solid;
                              margin-left: 34px;
                              text-align: center;
                              font-size: 18px;
                              font-family: inherit;
                              font-weight: 300;
                              color: inherit;
                              outline: none;
                              align-self: center;
                         }

                         ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                              color: #D9D9D9;
                              opacity: 1; /* Firefox */
                         }
                            
                         :-ms-input-placeholder { /* Internet Explorer 10-11 */
                              color: #D9D9D9;;
                         }
                            
                         ::-ms-input-placeholder { /* Microsoft Edge */
                              color: #D9D9D9;;
                         }


                         .hiddenBtn {
                              display: none;

                         }


                    }


               }

               .questCont {
                    // width: 70%;
                    display: flex;
                    // height: 100%;

                    @media only screen and (max-width: 500px) {
                         overflow: scroll;
                    }

                    .questions {
                         margin-top: 12px;
                         height: 208px;
                         width: 114px;
                         overflow-y: scroll;

                         @media only screen and (max-width: 500px) {
                              max-width: 114px;
                         }

                         .innerQuest {
                              padding-top: 8px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-family: 'GothamMedium';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 12px;
                              color: #333333;
                              height: 36px;
                              border-radius: 100px 0px 0px 100px;
                         }
                    }

                    .questionsData {
                         width: 100%;
                         height: 100%;
                         background-color: #EEEEEE;
                         border-radius: 15px;
                         display: flex;
                         justify-content: center;
                         flex-direction: column;
                         // align-items: center;

                         @media only screen and (max-width: 500px) {
                              max-width: 100%;
                              max-height: 100%;
                              overflow: scroll;
                              flex-wrap: wrap;
                         }

                         .inputCon {
                              // max-width: 503px; 
                              height: 35px;
                              background: #D9D9D9;
                              border-radius: 5px;
                              border: 0px;
                              margin: 15px 25px 17px 25px;
                         }

                         .form {
                              margin-left: 25px;
                              display: flex;
                              flex-wrap: wrap;
                              // max-width: 600px; 

                              @media only screen and (max-width: 500px) {
                                   display: block;
                                   height: none;
                                   flex-wrap: nowrap;
                                   max-width: 600px;

                              }

                              .checkboxContain {
                                   display: flex;
                                   flex-direction: row;
                                   margin-right: 18px;

                                 

                                   .checkbox {
                                        width: 15px;
                                        height: 15px;
                                        align-self: center;
                                        margin-right: 15px;
                                   }

                                   .inputAnswers {
                                        width: 206px;
                                        height: 35px;
                                        background: #D9D9D9;
                                        border-radius: 5px;
                                        border: 0px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 13px;
                                        color: #333333;
                                   }

                                   .deleteIcon {
                                        margin: 8px;
                                        width: 16px;
                                        height: 16px;
                                        align-self: center;
                                        margin-right: 15px;
                                        cursor: pointer;
                                   }
                              }

                              .correctAns {
                                   font-family: 'GothamMedium';
                                   font-style: normal;
                                   font-weight: 400;
                                   font-size: 9px;
                                   color: #333333;
                                   margin-top: 12px;
                              }
                         }

                    }
               }
          }

          .separator {
               margin-top: 35px;
               margin-bottom: 8px;
               width: 100%;
               height: 0px;
               opacity: 0.3;
               border: 1px solid #000000;
          }
     }

     .mainCon::-webkit-scrollbar {
          width: 6px;
     }

     // .mainCon::-webkit-scrollbar-track {
     //       background: red;
     // }
     .mainCon::-webkit-scrollbar-thumb {
          border: 6px solid #75787B;
          box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
          border-radius: 30px;
     }

     .questions {
          -ms-overflow-style: none;
          scrollbar-width: none;
     }

     .questions::-webkit-scrollbar {
          display: none;
     }

     input:focus {
          outline: none;
     }
}

.uploadBtn {
     cursor: pointer;
     align-self: center;
     margin-bottom: 8px;
     width: 206px;
     height: 40px;
     border: 0px;
     background-color: #333333;
     border-radius: 5px;
     font-family: 'GothamMedium';
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     color: #FFFFFF;
     padding-top: 8px;
}

.bottomContainer{
     display: flex;
     padding-top: 8px;
     justify-content: flex-end;
     flex-wrap: wrap;

     @media only screen and (max-width: 500px) {
          justify-content: center;
     }

     .questionsBtn {
          cursor: pointer;
          width: 206px;
          height: 40px;
          border: 0px;
          background-color: #333333;
          border-radius: 5px;
          font-family: 'GothamMedium';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #FFFFFF;
          padding-top: 8px;
          margin-right: 20px;
          margin-top: 8px;
     }
}

.addLevelCont{
     display: flex;
     padding-top: 8px;
     justify-content: center;
     flex-wrap: wrap;
     margin-bottom: 20px;

     .questionsBtn {
          cursor: pointer;
          width: 206px;
          height: 40px;
          border: 0px;
          background-color: #FFFFFF;
          border-radius: 5px;
          font-family: 'GothamMedium';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #333333;
          padding-top: 8px;
          margin-right: 20px;
          margin-top: 8px;
     }
}

}