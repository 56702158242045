.mainContainer{
     background-color: #66435A;
     display: flex;
     // flex-direction: row;
     // align-items: center;
     // justify-content: center;
     height: 100vh;
     width: 100%;
     overflow: scroll;


     @media only screen and (max-width: 1150px) {
          padding-left: 20px;
          padding-right: 20px;
     }
     

     .container {
     // background-color: white;
     display: flex;
     // height: 100vh;
     flex-direction: column;
     width: 100%;
     margin: 20px 56px 15px -20px;
     background-color: #0E6B8C;
     border-radius: 30px;

     @media only screen and (max-width: 1150px) {
          margin-left: 10px;
     }

     @media only screen and (min-width: 1150px) {
          width: 76vw;
     }

     .upperCon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          // justify-content: end;
          margin: 28px 23px 19px 35px;

          @media only screen and (max-width: 650px) {
               flex-wrap: wrap;
          }

          .newUserBtn {
               // display: none;
               padding-top: 8px;
               border: none;
               width: 134px;
               height: 35px;
               background-color: #F1C400;
               box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
               border-radius: 5px;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               text-align: center;
               color: #333333;

               @media only screen and (max-width: 650px) {
                    margin-bottom: 10px;
               }
          }

          .searchBar {
               padding-top: 6px;
               width: 341px;
               height: 34px;
               background-color: #75787B;
               // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
               border-radius: 30px;

               @media only screen and (max-width: 650px) {
                    width: 100%;
               }

               input {
                    background-color: #75787B;
                    width: 301px;
                    height: 85%;
                    border-radius: 30px;
                    padding-left: 4px;
                    border: none;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    color: #FFFFFF;
                    padding-left: 15px;

                    @media only screen and (max-width: 650px) {
                         width: 91%;
                    }

               }

               input::placeholder {
                    color: #FFFFFF;
                    opacity: 0.6; /* Firefox */
               }
                  
               input::-ms-input-placeholder { /* Edge 12 -18 */
                    color:  #FFFFFF;
                    opacity: 0.6;
               }

               .iconStyl {
                    width: 12.95px;
                    height: 12.96px;
                    cursor: pointer;
               }
          }
     }


     .table {
          background-color: #0E6B8C;
          border-radius: 5px;
          // width: 100%;
          margin: 0px 23px 35px 35px;
          max-height: 800px;
          overflow-y: scroll;

          @media only screen and (max-width: 1100px) {
               background-color: "transparent";
          }

          thead tr th {
               background-color: #0c3244;
          }

          tbody tr {
               background-color: #0E6B8C;
          }

          tr th {
               font-style: normal;
               font-weight: 700;
               font-size: 14px;
               letter-spacing: 0.05em;
               color: #FFFFFF !important;

               &::before {
                    display: none !important;
               }

               div {
                    display: flex !important;
                    align-items: center !important;
                    align-content: center !important;
                    justify-content: center !important;
                    height: 100%;
                    width: 100%;

                    p {
                         align-self: center;
                         margin: 0px !important;
                         padding-top: 9px;
                    }
               }
          }

          tr th,
          td {
               text-align: center;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               color: #FFFFFF !important;
          }

          .deleteIcons {
               width: 19px;
               height: 19px;
               margin: 3px;
               cursor: pointer;
          }

          // .bottom-border>tr,
          // td {
          //      border-bottom: transparent !important
          // }

          .top-border>tr,
          td,
          th {
               border-top: transparent !important;
               border-bottom: transparent !important
          }

          .custom-row-hover:hover tr,
          td {
               background-color: transparent !important;
          }

          .odd {
               background-color: #216585;
               border-radius: 5px;
          }

          .even {
               background-color: #0E6B8C;
          }

     }


     .table::-webkit-scrollbar {
          width: 8px;
          height: 8px;
     }

     .table::-webkit-scrollbar-thumb {
          border: 8px solid #75787B;
          box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
          border-radius: 30px;
     }

     // .table {
     //      -ms-overflow-style: none;
     //      /* Internet Explorer 10+ */
     //      scrollbar-width: none;
     //      /* Firefox */
     // }

     // .table::-webkit-scrollbar {
     //      display: none;
     //      /* Safari and Chrome */
     // }

     input:focus {
          outline: none;
     }
}}