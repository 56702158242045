.mainContainer{     
     background-color: #66435A;
     display: flex;
     // flex-direction: row;
     // align-items: center;
     // justify-content: center;
     height: 100vh;
     width: 100%;
     overflow: scroll;


     @media only screen and (max-width: 1150px) {
          padding-left: 20px;
          padding-right: 20px;
     }


     .container {
     // background-color: white;
     display: flex;
     height: 100vh;
     flex-direction: column;
     width: 100vw;
     padding: 35px 56px 30px 0px;


     overflow-y: scroll;

     &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
     }

     &::-webkit-scrollbar-thumb {
          border: 8px solid #75787B;
          box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
          border-radius: 30px;
     }

     @media only screen and (max-width: 1150px) {
          width: 100%;
          height: 100%;

     }

     .heading {
          font-family: 'GothamMedium';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 23px;
          color: #FFFFFF;
          margin-top: 20px;
     }



     .analyticsContainer {
          display: flex;
          // overflow: scroll;
          flex-wrap: wrap;
          padding-left: 20px;

          .analyticsCon {
               margin-right: 10px;
               margin-bottom: 10px;
               width: 250px;
               height: 131px;
               // background: #F1C400;
               border-radius: 24px;

               .upperCon {
                    display: flex;
                    margin-left: 21px;
                    margin-top: 37px;

                    .iconStyles {
                         width: 64px;
                         height: 36px;
                    }

                    .iconStyleb {
                         width: 54px;
                         height: 36px;
                    }

                    .amount {
                         margin-top: -4px;
                         margin-left: 11px;
                         font-family: 'GothamMedium';
                         font-style: normal;
                         font-weight: 700;
                         font-size: 40px;
                         // line-height: 38px;
                         color: #FFFFFF;
                    }
               }

               .title {
                    margin-top: -30px;
                    margin-left: 21px;
                    font-family: 'GothamMedium';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    // line-height: 23px;
                    color: #FFFFFF;
               }
          }
     }

     .table {
          background-color: #FFFFFF;
          border-radius: 30px;

          &:last-child {
               margin-bottom: 20px;
          }

          // width: 80vw;
          // width: 400px;

          @media only screen and (max-width: 1150px) {
               width: 100%;
          }

          .certificateBtn {
               padding-top: 10px;
               font-family: 'GothamMedium';
               width: 105px;
               height: 35px;
               background: #A0C23B;
               border-radius: 5px;
               border: none;
               color: #FFFFFF;
               cursor: pointer;
          }

          tr th {
               background-color: #333333;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 700;
               font-size: 14px;
               line-height: 12px;
               letter-spacing: 0.05em;
               color: #FFFFFF !important;

               &::before {
                    display: none;
               }

               div {
                    display: flex !important;
                    align-items: center !important;
                    align-content: center !important;
                    justify-content: center !important;
                    height: 100%;
                    width: 100%;

                    p {
                         align-self: center;
                         margin: 0px !important;
                         padding-top: 9px;
                    }
               }
          }

          tr th,
          td {
               text-align: center;
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 400;
               font-size: 14px;
               line-height: 20px;
               color: #75787B;
          }
     }
}}