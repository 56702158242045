.container {
     // display: none;
     background-color: #FFFFFF;
     border-radius: 30px;
     margin: 20px;
     width: 273px;
     position: relative;
     overflow-y: scroll;
     display: flex;
     flex-direction: column;
     // justify-content: space-between;
     // &:last-child {
     //      position: absolute;
     //      background-color: red;
     // }

     &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
     }

     &::-webkit-scrollbar-thumb {
          border: 8px solid #75787B;
          box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
          border-radius: 30px;
     }


     @media only screen and (max-width: 1150px) {
          display: none;
     }

     .userContainer {
          display: flex;
          flex-direction: column;
          align-items: center;

          .avatarStyl {
               width: 100px;
               height: 100px;
               margin: 30px 0px 25px 0px;
          }

          .username {
               font-family: 'GothamMedium';
               font-style: normal;
               font-weight: 700;
               font-size: 18px;
               line-height: 16px;
               color: #333333;
          }
     }

     .list {
          max-height: 535px;
          overflow-x: hidden;

          .listItem {
               display: flex;
               align-items: center;
               height: 61px;
               cursor: pointer;

               .iconStyles {
                    width: 18px;
                    height: 18px;
                    margin: 0px 12px 0px 26px;
                    object-fit: contain;
               }

               .listText {
                    font-family: 'GothamMedium';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    padding-top: 10px;
                    margin-top: 12px;
               }

          }
     }

     .listSeparator {
          width: 250px;
          height: 0px;
          border: 1px solid #EDEDED;
     }

     .logoutCont {
          // background-color: red;
          display: flex;
          justify-content: center;
          // position: absolute;
          bottom: 0;
          left: 0;
          right: 0;

          .logout {
               // background-color: green;
               cursor: pointer;
               font-family: 'GothamMedium';
               font-style: normal;
               margin-top: 12px;
               padding-top: 8px;
               font-weight: 700;
               font-size: 14px;
               line-height: 16px;
               color: #333333;
          }
     }

}